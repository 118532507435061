import { onMounted, onUnmounted, ref, watch } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@/shared/lib/browser";
import { COLLAPSED_SIDEBAR_MENU_KEY } from "@/shared/config";

interface IMenuSettings {
    isTokenSimulation: boolean;
    isFullscreen: boolean;
    isCamundaPanel: boolean;
    isCheckErrors: boolean;
    isHiddenMenu: boolean;
    isHiddenSideBar: boolean;

    isHiddenHeader: boolean;
}

export const useDiagramActionMenuStore = defineStore("diagram-action-menu", () => {
    const { setLSValue, value } = useLocalStorage<boolean>(COLLAPSED_SIDEBAR_MENU_KEY);

    const menuSettings = ref<IMenuSettings>({
        isCamundaPanel: false,
        isCheckErrors: false,
        isFullscreen: false,
        isHiddenMenu: false,
        isTokenSimulation: false,
        isHiddenSideBar: value,

        isHiddenHeader: false,
    });

    function toggleMenuSettings(key: keyof IMenuSettings, value?: boolean): void {
        if (key in menuSettings.value) menuSettings.value[key] = value === undefined ? !menuSettings.value[key] : value;
    }

    // Включение полноэкранного режима
    async function enterFullscreen(): Promise<void> {
        try {
            await document.body.requestFullscreen();

            const editor = document.querySelector(".editor");
            const diagramContainer = document.querySelector(".diagram-container");

            if (editor) editor.classList.add("editor_fullscreen");
            if (diagramContainer) {
                diagramContainer.classList.add("h-screen");
                diagramContainer.classList.remove("content");
            }

            toggleMenuSettings("isFullscreen", true);
        } catch (error) {
            console.error("Не удалось включить полноэкранный режим:", error);
        }
    }
    // Выход из полноэкранного режима
    async function exitFullscreen(isExitFullscreen: boolean = true): Promise<void> {
        try {
            const editor = document.querySelector(".editor");
            const diagramContainer = document.querySelector(".diagram-container");

            if (editor) editor.classList.remove("editor_fullscreen");
            if (diagramContainer) {
                diagramContainer.classList.remove("h-screen");
                diagramContainer.classList.add("content");
            }

            if (isExitFullscreen) await document.exitFullscreen();

            toggleMenuSettings("isFullscreen", false);
        } catch (error) {
            console.error("Не удалось выйти из полноэкранного режима:", error);
        }
    }
    // Переключение полноэкранного режима
    function toggleFullscreen(): void {
        if (document.fullscreenElement) {
            exitFullscreen();
        } else {
            enterFullscreen();
        }
    }
    // Обработчик изменения полноэкранного режима
    function handleFullscreenChange(): void {
        if (!document.fullscreenElement) exitFullscreen(false);
    }

    function toggleHeaderVisible(): void {
        toggleMenuSettings("isHiddenMenu");

        const editor = document.querySelector(".editor");
        if (editor) {
            editor.classList.toggle("editor_fullscreen", menuSettings.value.isHiddenMenu);
        }
    }

    function toggleSideBarVisible(): void {
        toggleMenuSettings("isHiddenSideBar");

        setLSValue(menuSettings.value.isHiddenSideBar);

        if (menuSettings.value.isHiddenSideBar) {
            document.querySelector(".editor")?.classList?.add("editor_hiddenSideBar");
        } else {
            document.querySelector(".editor")?.classList?.remove("editor_hiddenSideBar");
        }
    }

    function resetMenu(): void {
        for (const key in menuSettings.value) {
            menuSettings.value[key as keyof IMenuSettings] = false;
        }
    }

    watch([() => menuSettings.value.isFullscreen, () => menuSettings.value.isHiddenMenu], values => {
        toggleMenuSettings("isHiddenHeader", values[0] || values[1]);
    });

    onMounted(() => {
        document.addEventListener("fullscreenchange", handleFullscreenChange);
    });

    onUnmounted(() => {
        document.removeEventListener("fullscreenchange", handleFullscreenChange);
    });

    return {
        toggleFullscreen,
        toggleHeaderVisible,
        toggleSideBarVisible,

        resetMenu,

        menuSettings,
        toggleMenuSettings,
    };
});
