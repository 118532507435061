import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";
import { setTitlePage } from "@/shared/lib/browser";
import { ShareProcessTypes } from "@/shared/types";
import starterXML from "@/shared/assets/bpmn/starter.bpmn?raw";

import {
    EProcessSecure,
    type IProcess,
    type IAutosaveDiagram,
    type IDiagram,
    type IOnePorcessResponse,
    type IUploadDiagram,
    type IOverlaySettings,
    type IDiagramShort,
} from "..";
import { IDiagramShortFilter, ProcessApi } from "../..";
import { useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";

export const useDiagramStore = defineStore("diagram", () => {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { isLoading: isLoadingCreate, startLoading: startLoadingCreate, finishLoading: finishLoadingCreate } = useLoading();
    const { isLoading: loadingShort, startLoading: startLoadingShort, finishLoading: finishLoadingShort } = useLoading();

    const { showError, showSuccess } = useNotification();
    const router = useRouter();

    const diagramAccessAllowed = ref(true);
    const diagram = ref<IDiagram>();
    const diagramPermissionByMe = ref<EProcessSecure>();
    const isZoom = ref<boolean>(false);

    const whenAutoSave = ref<Date>();

    // Часто запрашиваемые параметры диаграммы
    const diagramName = computed<string>(() => diagram.value?.name || "");
    const diagramId = computed<string>(() => diagram.value?.id || "");

    // Тип диапграммы
    const isBPMN = computed<boolean>(() => diagram.value?.type === ShareProcessTypes.EDiagramType.BPMN);

    // Уровень доступа к диаграммам
    const isDiagramReadOnly = computed<boolean>(() => diagramPermissionByMe.value !== EProcessSecure.EDIT);
    const isDiagramEditable = computed<boolean>(() => diagramPermissionByMe.value === EProcessSecure.EDIT);

    /**
 * Resets the diagram state to its initial values.
 *
 * @remarks
 * Clears the diagram content, resets permission level to EDIT,
 * and clears the auto-save timestamp.
 */
function resetDiagram() {
        diagram.value = undefined;
        diagramPermissionByMe.value = EProcessSecure.EDIT;
        whenAutoSave.value = undefined;
    }
    function updateVisibility(): void {
        if (diagram.value) {
            diagram.value.public = !diagram.value.public;
            if (diagram.value.public) {
                showSuccess("Пользователи видят вашу диаграмму");
            } else {
                showSuccess("Пользователи не видят вашу диаграмму");
            }
        }
    }

    async function setDiagram(diagramData: IDiagram) {
        diagram.value = diagramData;

        const routerName = isBPMN.value ? ERoutesName.APP_DIAGRAM_ITEM : ERoutesName.APP_BCM_DIAGRAM_ITEM;

        await router.replace({ name: routerName, params: { id: diagramData.id } });
        setTitlePage(diagramData.name);
    }
    function setDiagramBody(xml: string): void {
        if (diagram.value) {
            diagram.value.body = xml;
        }
    }

    const diagramCallActivitySearch = ref<IProcess[]>([]);
    async function fetchCallActivitySearch() {
        try {
            const data = await ProcessApi.fetchCallActivitySearch();
            diagramCallActivitySearch.value = data;
        } catch (e: any) {
            diagramCallActivitySearch.value = [];
            showError(e?.message || e);
        }
    }
    function findCallActivityById(id: string): undefined | IProcess {
        return diagramCallActivitySearch.value.find(item => item.id === id);
    }
    function addToTopCallActivity(callActivity: IProcess) {
        diagramCallActivitySearch.value.unshift(callActivity);
    }

    const shortDiagrams = ref<IDiagramShort[]>([]);
    const totalShortDiagrams = ref<number>(0);
    const isLastPageShort = ref<boolean>(false);
    async function fetchAllShortDiagrams(filter?: IDiagramShortFilter, reset: boolean = false): Promise<void> {
        try {
            startLoadingShort();

            /* Сброс последней страницы */
            if (filter?.diagramName || reset || !filter) {
                isLastPageShort.value = false;
            }

            const response = await ProcessApi.fetchAllShortBy(filter);

            if ((filter?.page || 0) > 0) {
                shortDiagrams.value.push(...response.content);
            } else {
                shortDiagrams.value = response.content;
            }

            const indexCurrentDiagram: number = shortDiagrams.value.findIndex(item => item.id === diagramId.value);
            if (indexCurrentDiagram >= 0) {
                shortDiagrams.value.splice(indexCurrentDiagram, 1);
            }

            totalShortDiagrams.value = response.totalElements;

            if (!filter?.diagramName) isLastPageShort.value = response.last;
        } catch (e) {
            shortDiagrams.value = [];
            showError(e);
        } finally {
            finishLoadingShort();
        }
    }
    function findShortDiagramById(id: string): undefined | IDiagramShort {
        return shortDiagrams.value.find(item => item.id === id);
    }
    function addToTopShortDiagram(diagram: IDiagramShort): void {
        shortDiagrams.value.unshift(diagram);
    }

    async function fetchById(id: string): Promise<void> {
        try {
            startLoading();

            diagram.value = undefined;
            diagramAccessAllowed.value = true;
            isZoom.value = true;

            const data: IOnePorcessResponse = await ProcessApi.fetchById(id);
            diagram.value = data.diagram;
            diagramPermissionByMe.value = data.permission;
        } catch (e: any) {
            if (e.status === 403) {
                diagramAccessAllowed.value = false;
            } else {
                showError(e?.message || e);
            }
        } finally {
            finishLoading();
        }
    }
    async function createDiagram(payload: IUploadDiagram) {
        try {
            isZoom.value = false;

            const data = await ProcessApi.uploadDiagram(payload);
            setDiagram(data);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function createDraft(payload: { name: string; type: ShareProcessTypes.EDiagramType }): Promise<undefined | IProcess> {
        try {
            startLoadingCreate();

            const isBPMN: boolean = payload.type === ShareProcessTypes.EDiagramType.BPMN;

            const data: IUploadDiagram = {
                ...payload,
                public: true,
                body: isBPMN ? starterXML : "",
            };

            return await ProcessApi.createDraft(data);
        } catch (e: any) {
            showError(e?.message || e);
            return;
        } finally {
            finishLoadingCreate();
        }
    }
    async function autosave(payload: IAutosaveDiagram) {
        try {
            if (diagram.value) {
                const data = await ProcessApi.autosave(diagram.value.id, payload);
                diagram.value.autosaveIndex = data.autosaveIndex;
                diagram.value.body = payload.body;

                whenAutoSave.value = new Date();
            }
        } catch (e) {
            showError(e);
        }
    }

    const overlaySettings = ref<IOverlaySettings>({
        assignees: false,
        comments: false,
        description: false,
        documents: false,
        duration: false,
        incomingLinks: false,
        links: false,
        positions: false,
        systems: false,
    });
    async function fetchOverlaySettingsById(diagramId: string): Promise<void> {
        try {
            const response = await ProcessApi.fetchOverlaySettingsById(diagramId);
            if (response) overlaySettings.value = response;
        } catch (e) {
            showError(e);
        }
    }

    return {
        isLoading,
        isZoom,
        diagram,
        diagramName,
        diagramId,
        diagramAccessAllowed,
        isDiagramReadOnly,
        isDiagramEditable,
        whenAutoSave,

        diagramCallActivitySearch,
        fetchCallActivitySearch,
        findCallActivityById,

        isBPMN,

        resetDiagram,
        fetchById,
        createDiagram,
        autosave,
        setDiagram,
        setDiagramBody,

        overlaySettings,
        fetchOverlaySettingsById,
        updateVisibility,

        isLoadingCreate,
        createDraft,
        addToTopCallActivity,

        loadingShort,
        shortDiagrams,
        totalShortDiagrams,
        isLastPageShort,
        fetchAllShortDiagrams,
        findShortDiagramById,
        addToTopShortDiagram,
    };
});
