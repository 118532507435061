<template>
    <Button
        :icon="buttonParams.icon"
        :text="!menuSettings.isTokenSimulation"
        class="navbar-button button_clean-outline"
        severity="contrast"
        v-tooltip.right="buttonParams.tooltipText"
        @click="toggle"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useDiagramActionMenuStore } from "@/entities/Process";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { ShareInterfaceTypes } from "@/shared/types";

const actionMenuStore = useDiagramActionMenuStore();
const modelerStore = useBpmnModelerStore();

const { menuSettings } = storeToRefs(actionMenuStore);
const { simulationSupport } = storeToRefs(modelerStore);

const buttonParams = computed<ShareInterfaceTypes.IButtonMenubarProps>(() => {
    const isTokenSimulation = menuSettings.value.isTokenSimulation;
    return {
        icon: isTokenSimulation ? PrimeIcons.STOP_CIRCLE : PrimeIcons.PLAY_CIRCLE,
        tooltipText: isTokenSimulation ? "Выключить симуляцию токена" : "Включить симуляцию токена",
    };
});

function toggle(): void {
    actionMenuStore.toggleMenuSettings("isTokenSimulation");
    simulationSupport.value.toggleSimulation();
}
</script>

<style scoped></style>
