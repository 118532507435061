import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import type { Shape } from "bpmn-js/lib/model/Types";

import { useNotification } from "@/shared/lib/composables";

import { useBpmnModelerStore } from ".";
import { isSameArrays } from "@/shared/lib/utils/array";
import { EBpmnElementType } from "@/entities/Score";

interface ISelectElementOptions {
    isHideMenu: boolean;
}

export const useElementStore = defineStore("element", () => {
    const { showWarning } = useNotification();

    const bpmnModelerStore = useBpmnModelerStore();
    const { modelerElementRegistry, modelerSelection, modelerContextPad, modelerCanvas, modelerModeling } = storeToRefs(bpmnModelerStore);

    const selectedElements = ref<Shape[]>([]);

    const hasSelectedElements = computed<boolean>(() => selectedElements.value.length > 0);
    const selectedElement = computed<undefined | Shape>(() => {
        if (hasSelectedElements.value) {
            const element = selectedElements.value[0];
            return getElementById(element.id);
        }
        return undefined;
    });
    const isActivitySelected = computed<boolean>(() => {
        if (!selectedElement.value) return false;

        const elementType = selectedElement.value.type as EBpmnElementType;

        return (
            [EBpmnElementType.CALL_ACTIVITY, EBpmnElementType.PROCESS, EBpmnElementType.SUB_PROCESS].includes(elementType) ||
            elementType.includes("Task")
        );
    });

    function getElementById(elementId: string): undefined | Shape {
        if (!modelerElementRegistry.value) return undefined;
        return modelerElementRegistry.value.get(elementId);
    }

    function selectElement(elementId: string, options?: ISelectElementOptions): void {
        const element = getElementById(elementId);
        if (!element) return showWarning("Элемент не найден");

        if (modelerSelection.value.isSelected(element)) return;

        modelerSelection.value.select(element);
        if (options?.isHideMenu) modelerContextPad.value.close();
    }
    async function goToElement(elementId: string): Promise<void> {
        const element = modelerElementRegistry.value.get(elementId);
        if (!element) {
            showWarning("Элемент не найден");
            return;
        }

        const viewbox = modelerCanvas.value.viewbox();
        const box = element;

        const newViewbox = {
            x: box.x + box.width / 2 - viewbox.outer.width / 2,
            y: box.y + box.height / 2 - viewbox.outer.height / 5,
            width: viewbox.outer.width,
            height: viewbox.outer.height,
        };

        modelerCanvas.value.viewbox(newViewbox);

        modelerCanvas.value.zoom(1.2);

        selectElement(elementId);
    }
    function deselectElement(elementId: string) {
        const element = modelerElementRegistry.value.get(elementId);
        if (!element) return;
        modelerSelection.value.deselect(element);
    }

    function setSelectedElements(elements: Shape[]) {
        const ids = elements.map(item => item.id);
        const selectedIds = selectedElements.value.map(item => item.id);

        const isSame = isSameArrays(ids, selectedIds);

        if (!isSame) selectedElements.value = JSON.parse(JSON.stringify(elements));
    }

    function clearSelectedElements() {
        selectedElements.value = [];
    }

    function changeNameByElement(element: any, name: string): void {
        if (element) {
            modelerModeling.value.updateProperties(element, { name });
        }
    }

    return {
        selectElement,
        deselectElement,

        hasSelectedElements,

        selectedElements,
        selectedElement,
        setSelectedElements,
        clearSelectedElements,

        getElementById,
        goToElement,

        changeNameByElement,

        isActivitySelected,
    };
});
