import { shallowRef, ref, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { PrimeIcons } from "primevue/api";
import { EEditorToggleNavigationName, IAssigneeActivity, IAssetItem, IActivityItem } from "@/shared/lib/types/editor-toggle";
import { isActivities } from "@/entities/Score";
import { useBpmnModelerStore, useElementStore } from "@/entities/BpmnModeler";
import { useAssigneeStore, IAssignee, EAssigneeType } from "@/entities/Assignee";
import { useAssetByDiagramStore, EAssetType, IAssetEdge } from "@/entities/Asset";

const activity: Ref<IActivityItem[]> = ref([]);

const buttonOptions: EEditorToggleNavigationName[] = Object.values(EEditorToggleNavigationName);

const selectedButton = shallowRef<EEditorToggleNavigationName>(buttonOptions[0]);

export function useDiagramToggleEditorView() {
    const elementStore = useElementStore();
    const assigneeStore = useAssigneeStore();
    const bpmnModelerStore = useBpmnModelerStore();
    const assetByDiagramStore = useAssetByDiagramStore();

    const { assetsByDiagram } = storeToRefs(assetByDiagramStore);
    const { modelerElementRegistry } = storeToRefs(bpmnModelerStore);
    const { assigneesByDiagram } = storeToRefs(assigneeStore);

    function filterForActivity(element: any): boolean {
        return isActivities(element.type);
    }

    function getElementName(elementId: string): string {
        const element = elementStore.getElementById(elementId);

        if (element) {
            return element.businessObject.name || "";
        }

        return "";
    }

    function getElementAssigneeIcon(elementId: string): string {
        for (const item of assigneesByDiagram.value) {
            if (item.elementId === elementId) {
                if (item.assigneeType === EAssigneeType.HUMAN) {
                    return PrimeIcons.USER;
                } else if (item.assigneeType === EAssigneeType.SYSTEM) {
                    return PrimeIcons.ANDROID;
                } else return PrimeIcons.QUESTION;
            }
        }

        return "";
    }

    function getElementAssignee(elementId: string): IAssigneeActivity {
        const assignee: IAssigneeActivity = {
            id: null,
            name: "",
            icon: "",
            color: "",
        };

        const foundedAssignee = assigneesByDiagram.value.find(item => {
            if (item.elementId === elementId) {
                return item;
            }
        });

        if (foundedAssignee) {
            (assignee.id = foundedAssignee.assigneeId),
                (assignee.name = foundedAssignee.assigneeName),
                (assignee.color = foundedAssignee.color);
            assignee.icon = getElementAssigneeIcon(elementId);
        }

        return assignee;
    }

    function elementAssetIcon(item: IAssetEdge): EAssetType | string {
        const assetsType: Record<string, string> = {
            ACTION: PrimeIcons.FILE,
            CLIENT: PrimeIcons.USER,
            COMMUNICATION: PrimeIcons.ENVELOPE,
            DOCUMENT: PrimeIcons.FILE,
            ENTITY: PrimeIcons.DATABASE,
            OTHER: PrimeIcons.TH_LARGE,
            SYSTEM: PrimeIcons.MICROCHIP,
            UNSPECIFIED: PrimeIcons.QUESTION,
        };

        return assetsType[item.assetType] || PrimeIcons.QUESTION;
    }

    function getElementAssets(elementId: string): { docs: IAssetItem[]; systems: IAssetItem[] } {
        const systems: IAssetItem[] = [];
        const docs: IAssetItem[] = [];

        for (const item of assetsByDiagram.value) {
            if (item.fromItemId === elementId) {
                const asset = {
                    id: item.assetId,
                    name: item.assetName,
                    type: item.assetType,
                    icon: elementAssetIcon(item),
                };
                if (item.assetType === EAssetType.SYSTEM) {
                    systems.push(asset);
                }
                if (item.assetType === EAssetType.DOCUMENT) {
                    docs.push(asset);
                }
            }
        }
        return {
            docs,
            systems,
        };
    }

    function replaceNewAssignee(assignee: IAssignee): void {
        if (selectedButton.value === EEditorToggleNavigationName.TABLE) {
            activity.value.forEach((item: IActivityItem) => {
                if (typeof item.role === "object" && item.role.id === assignee.id) {
                    item.role.name = assignee.name;
                    item.role.color = assignee.color;
                }
            });
        }
    }

    async function setItem(item: any): Promise<void> {
        selectedButton.value = item;

        if (selectedButton.value !== EEditorToggleNavigationName.DIAGRAM) {
            document.querySelector(".editor")?.classList?.add("hidden");
        } else {
            document.querySelector(".editor")?.classList?.remove("hidden");
        }

        if (selectedButton.value === EEditorToggleNavigationName.TABLE) {
            await getAllActivityElements();
        }
    }

    async function getAllActivityElements(): Promise<void> {
        const newArr = await modelerElementRegistry.value.filter(filterForActivity);

        activity.value = [];

        for (const item of newArr) {
            const { docs, systems } = getElementAssets(item.id);

            const newActivity = {
                id: item.id,
                name: getElementName(item.id),
                parent: item.parent ? getElementName(item.parent.id) : "",
                role: getElementAssignee(item.id),
                docs: docs,
                systems: systems,
            };

            activity.value.push(newActivity);
        }
    }

    return {
        buttonOptions,
        selectedButton,
        activity,
        filterForActivity,
        getAllActivityElements,
        replaceNewAssignee,
        setItem,
    };
}
