export function downloadFileByUrl(url: string, name?: string): void {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    if (name) link.setAttribute("download", name);
    link.click();
    link.remove();
}

export async function downloadFile(text: string | File | ArrayBuffer | Blob, fileType: string, name: string) {
    const blob = new Blob([text], {
        type: fileType,
    });
    const element = document.createElement("a");
    element.download = name;
    element.href = window.URL.createObjectURL(blob);
    element.dataset.downloadurl = [fileType, element.download, element.href].join(":");
    element.click();
    element.remove();
    window.URL.revokeObjectURL(element.href);
}
