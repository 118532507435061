<template>
    <FormColumn title="">
        <div class="flex gap-2">
            <Button label="SVG" outlined @click="downloadSVG" />
            <Button v-if="isBPMN" label="BPMN" severity="secondary" outlined @click="downloadXML" />
            <Button v-else label="JSON" severity="secondary" outlined @click="downloadJSON" />
            <Button label="PNG" severity="success" outlined @click="downloadPNG" />
            <span v-tooltip="tooltipText">
                <Button label="PDF" :severity="severityPDF" :disabled="!isAuth" outlined @click="downloadPDF" />
            </span>
        </div>
    </FormColumn>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { FormColumn } from "@/shared/ui/form";

import { useDiagramStore } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";

import { useDownload } from "@/features/Process/download";

const { downloadSVG, downloadXML, downloadPNG, downloadJSON, downloadPDF } = useDownload();

const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();
const { isBPMN } = storeToRefs(diagramStore);
const { isAuth } = storeToRefs(sessionStore);

const severityPDF = computed(() => (isAuth.value ? "help" : "secondary"));
const tooltipText = computed(() => (isAuth.value ? "" : "Доступно только для зарегистрированных пользователей"));
</script>

<style scoped></style>
