import { computed, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { ID_FOLDER_DEFAULT } from "@/shared/config";
import { useNotification, useLoading } from "@/shared/lib/composables";

import {
    EProcessStatus,
    EProcessStatusName,
    type IProcess,
    type IProcessQuery,
    type IProcessResponse,
    type ISearchProcessQuery,
    type ISearchProcessResponse,
} from "..";
import { ProcessApi } from "../../api";
import type { TProcessStatus } from "../../lib";

import { useSessionStore } from "@/entities/Session";
import { ETypeView, useInterfaceStore } from "@/entities/Interface";
import { usePaymentStore } from "@/entities/Payment";

import { ERoutesName } from "@/app/providers";

export const useProcessStore = defineStore("process", () => {
    const { showError } = useNotification();
    const { isLoading: loading, startLoading, finishLoading } = useLoading();
    const route = useRoute();

    const sessionStore = useSessionStore();
    const { isTeamPlan } = storeToRefs(sessionStore);
    const interfaceStore = useInterfaceStore();
    const paymentStore = usePaymentStore();

    const processes = ref<IProcess[]>([]);
    const totalProcesses = ref<number>(0);
    const activeProcess = ref<IProcess>();
    const selectedProcesses = ref<IProcess[]>([]);

    const hasProcesses = computed(() => processes.value.length > 0);
    const countSelectedProcesses = computed(() => selectedProcesses.value.length);
    const hasSelectedProcesses = computed(() => countSelectedProcesses.value > 0);

    const apiKey = computed(() => route.query.apiKey as string);

    const processStatuses = computed(() =>
        Object.keys(EProcessStatus).map(item => {
            const key = item as TProcessStatus;

            const label = EProcessStatusName[key];
            const value = EProcessStatus[key];

            return {
                label,
                value,
            };
        }),
    );

    function setActiveProcess(process: IProcess) {
        activeProcess.value = process;
    }

    /**
     * Clears all selected processes by resetting the selected processes array to empty.
     *
     * @remarks
     * This function modifies the reactive `selectedProcesses` value reference.
     */
    function clearSelectedProcesses() {
        selectedProcesses.value = [];
    }

    /**
     * Removes processes from the processes list by their IDs.
     *
     * @param ids - Array of process IDs to be removed from the processes list
     *
     * @remarks
     * This function mutates the processes.value reactive reference by filtering out
     * all processes whose IDs match any ID in the provided array.
     */
    function localeDeleteByIds(ids: string[]) {
        processes.value = processes.value.filter(item => !ids.includes(item.id));
    }

    /**
     * Toggles the public status of processes with matching IDs.
     *
     * @param ids - Array of process IDs whose public status should be toggled
     *
     * @remarks
     * This function mutates the processes array by inverting the public boolean flag
     * for each process whose ID matches any of the provided IDs.
     */
    function localeChangePublicByIds(ids: string[]) {
        for (const process of processes.value) {
            if (ids.includes(process.id)) {
                process.public = !process.public;
            }
        }
    }

    /**
     * Updates the status of a process identified by its ID.
     *
     * @param id - The unique identifier of the process to update
     * @param status - The new status to set for the process
     *
     * @remarks
     * This function mutates the processes array directly. If no process is found
     * with the given ID, no changes are made.
     */
    function setStatusById(id: string, status: EProcessStatus) {
        const finded = processes.value.find(item => item.id === id);
        if (finded) {
            finded.status = status;
        }
    }

    /**
     * Fetches and updates process data based on pagination, folder, and access settings.
     *
     * @param page - The page number for pagination, defaults to 0
     * @param folderId - Optional folder ID to filter processes. Use undefined for no folder filtering
     * @returns Promise that resolves when the fetch operation completes
     *
     * @throws Will show error message if the API request fails
     *
     * @remarks
     * - Requires valid team subscription for folder-specific or team-based queries
     * - Updates global processes and totalProcesses reactive values
     * - Handles loading state automatically
     */
    async function fetchProcessesBy(page: number = 0, folderId?: number | undefined): Promise<void> {
        try {
            startLoading();
            const selectedTypeView = interfaceStore.selectedTypeView;
            const enterpriseAccess = paymentStore.isEnterprisePlan;

            const isTeam = selectedTypeView === ETypeView.TEAM;
            const isDirectAccess = route.name === ERoutesName.APP_SHARE_WITH_ME;
            processes.value = [];

            const query: IProcessQuery = {
                team: isTeam,
                directAccess: isDirectAccess,
                enterpriseAccess: enterpriseAccess,
                apiKey: apiKey.value,
                page: page,
            };

            if (folderId) {
                if (folderId && folderId !== ID_FOLDER_DEFAULT) {
                    query.folderId = folderId;
                } else query.emptyFolder = true;
            }

            // Защита от получения даннх, если нет подписки
            if ((folderId || query.team) && !isTeamPlan.value) return;

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams;
            totalProcesses.value = data.totalElements;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function fetchUnpublic(): Promise<void> {
        try {
            startLoading();
            processes.value = [];
            processes.value = await ProcessApi.fetchUnpublic();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function search(query: ISearchProcessQuery): Promise<void> {
        try {
            startLoading();
            processes.value = [];

            const data: ISearchProcessResponse = await ProcessApi.search(query);
            processes.value = data.content;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    function updateProcess(process: IProcess): void {
        const index: number = processes.value.findIndex(item => item.id === process.id);
        if (index !== -1) {
            processes.value[index] = process;
        }
    }

    watch(route, () => {
        clearSelectedProcesses();
    });

    return {
        loading,
        processes,
        hasProcesses,
        activeProcess,
        selectedProcesses,
        countSelectedProcesses,
        hasSelectedProcesses,
        processStatuses,

        setStatusById,
        search,
        setActiveProcess,
        clearSelectedProcesses,
        localeDeleteByIds,
        localeChangePublicByIds,
        fetchUnpublic,

        totalProcesses,
        fetchProcessesBy,

        updateProcess,
    };
});
