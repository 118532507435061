import { storeToRefs } from "pinia";

import { API_URL, DEFAULT_DIAGRAM_NAME } from "@/shared/config";
import { useNotification } from "@/shared/lib/composables";
import { downloadFile } from "@/shared/lib/browser";
import { useDiagramStore, type IProcess, ProcessApi, type ILoadPDFSettings, type IPDFOverlaysSettings } from "@/entities/Process";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { BcmModel } from "@/entities/BcmModeler";
import { ref } from "vue";
import { useDialog } from "primevue/usedialog";
import { DownloadSpinner } from "@/shared/ui/download-spinner";
import { MODAL_PARAMS } from "@/shared/constants/modal";
import { createURL } from "@/shared/lib/browser";
import { useToggleOverlays } from "@/features/Diagram-editor/toggle-overlays";

export function useDownload() {
    const { showError } = useNotification();

    const { collapseOverlays } = useToggleOverlays();
    const diagramStore = useDiagramStore();
    const bpmnModelerStore = useBpmnModelerStore();
    const { saveJSON, saveSVG } = BcmModel.useBcmModeler();
    const { isBPMN, diagram, diagramId, diagramName, overlaySettings } = storeToRefs(diagramStore);
    const dialog = useDialog();
    const isPDFDownloaded = ref(false);

    async function downloadPDF(): Promise<void> {
        try {
            isPDFDownloaded.value = true;

            dialog.open(DownloadSpinner, {
                data: () => isPDFDownloaded.value,
                props: {
                    ...MODAL_PARAMS,
                    closable: false,
                    style: {},
                },
            });

            const query: ILoadPDFSettings = {
                landscape: true,
                marginLeft: 0,
                marginTop: 0,
                scale: 2,
            };
            const visibilityOverlays: IPDFOverlaysSettings = {
                visible: overlaySettings.value,
                collaps: collapseOverlays,
            };
            const pathURL = `/app/diagram/${diagramId.value}`;
            const overlaysBase64 = btoa(JSON.stringify(visibilityOverlays));

            const url = createURL(window.location.origin, pathURL, { overlays: overlaysBase64, screenshot: "true" });

            const pdf = await ProcessApi.downloadPDF(query, url);

            const fileType: string = "application/pdf";
            const fileName: string = diagramName.value + ".pdf";

            downloadFile(pdf, fileType, fileName);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            isPDFDownloaded.value = false;
        }
    }

    async function downloadPng(diagram: IProcess): Promise<void> {
        try {
            const { id, name } = diagram;
            const url = setImageUrl(id);

            const blob = await ProcessApi.downloadPng(url);

            downloadFile(blob, "application/png", name);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    function setImageUrl(diagramId: string): string {
        return API_URL + "files/" + diagramId + ".png";
    }

    async function downloadSVG(): Promise<void> {
        const svg = isBPMN.value ? await bpmnModelerStore.saveSVG() : await saveSVG();
        const fileType = "application/svg";
        const name = `${diagram.value?.name || DEFAULT_DIAGRAM_NAME}.svg`;
        downloadFile(svg, fileType, name);
    }

    async function downloadPNG(): Promise<void> {
        const svg = isBPMN.value ? await bpmnModelerStore.saveSVG() : await saveSVG();
        const fileName = (diagram.value?.name || DEFAULT_DIAGRAM_NAME) + ".png";
        const domUrl = window.URL || window.webkitURL || window;
        if (!domUrl) {
            throw new Error("(browser doesnt support this)");
        }

        const match_heigh = svg.match(/height=\"(\d+)/m);
        const height = match_heigh && match_heigh[1] ? parseInt(match_heigh[1], 10) : 200;
        const match_width = svg.match(/width=\"(\d+)/m);
        const width = match_width && match_width[1] ? parseInt(match_width[1], 10) : 200;
        const fill = "#ffffff";

        if (!svg.match(/xmlns=\"/im)) {
            svg.replace("<svg ", '<svg xmlns="http://www.w3.org/2000/svg" ');
        }

        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        const svgBlob = new Blob([svg], {
            type: "image/svg+xml;charset=utf-8",
        });

        const url = domUrl.createObjectURL(svgBlob);
        const img = new Image();

        img.onload = function () {
            if (ctx) {
                ctx.drawImage(img, 0, 0);
            }

            if (fill) {
                const styled = document.createElement("canvas");
                styled.width = canvas.width;
                styled.height = canvas.height;
                const styledCtx = styled.getContext("2d");
                if (styledCtx) {
                    styledCtx.save();
                    styledCtx.fillStyle = fill;
                    styledCtx.fillRect(0, 0, canvas.width, canvas.height);
                    styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
                    styledCtx.restore();
                    styledCtx.drawImage(canvas, 0, 0);
                }
                canvas = styled;
            }

            domUrl.revokeObjectURL(url);

            const downloadLink = document.createElement("a");
            downloadLink.download = fileName;
            downloadLink.innerHTML = "Get BPMN PNG";
            downloadLink.href = canvas.toDataURL();
            downloadLink.onclick = function (event: any) {
                document.body.removeChild(event.target);
            };
            downloadLink.style.visibility = "hidden";
            document.body.appendChild(downloadLink);
            downloadLink.click();
        };
        img.src = url;
    }

    async function downloadXML(): Promise<void> {
        const xml = await bpmnModelerStore.saveXML();
        const fileType = "application/bpmn20-xml";
        const name = `${diagram.value?.name || DEFAULT_DIAGRAM_NAME}.bpmn`;
        downloadFile(xml, fileType, name);
    }

    async function downloadJSON(): Promise<void> {
        const json = await saveJSON();
        const fileType = "application/json";
        const name = `${diagram.value?.name || DEFAULT_DIAGRAM_NAME}.json`;
        downloadFile(JSON.stringify(json), fileType, name);
    }

    return {
        downloadPng,
        isPDFDownloaded,

        downloadSVG,
        downloadXML,
        downloadJSON,
        downloadPNG,
        downloadPDF,
    };
}
