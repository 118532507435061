<template>
    <div class="w-full h-full custom-flex flex-column justify-content-center align-items-center">
        <div class="at-construction">
            <img src="@/shared/assets/images/png/Bug.png" />
        </div>
        <h2 class="text-4xl">Улучшаем функционал ...</h2>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

.at-construction {
    @include styleclass("w-20rem flex flex-column align-items-center");
}

.custom-flex {
    display: flex;
}
</style>
