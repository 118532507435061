<template>
    <DiagramToggleEditorView>
        <template #body>
            <div v-for="item in buttonOptions" class="flex gap-1">
                <Button :label="item" severity="contrast" :text="selectedButton !== item" @click="setItem(item)" />
            </div>
        </template>
    </DiagramToggleEditorView>
</template>

<script setup lang="ts">
import { DiagramToggleEditorView } from "@/shared/ui/diagram";
import { useDiagramToggleEditorView } from "@/features/Diagram-editor/toggle-editor-view";

const { buttonOptions, selectedButton, setItem } = useDiagramToggleEditorView();
</script>
