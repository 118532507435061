<template>
    <Teleport to="body">
        <div class="editor" :class="editorClass" id="modeler"></div>
    </Teleport>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface IProps {
    isEnabledTabs?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
    isEnabledTabs: false,
});

const editorClass = computed<string>(() => (props.isEnabledTabs ? "editor_enabled-tabs" : ""));
</script>

<style scoped lang="scss">
.editor {
    position: absolute;
    top: 8.7rem;
    left: 8px;
    bottom: 0.5rem;
    right: 290px;

    &_enabled-tabs {
        bottom: 3.8rem;
    }

    &_fullscreen {
        top: 3.6rem;
    }
    &_hiddenSideBar {
        right: 80px;
    }
    &_screenShot {
        top: 5rem;
        right: 0px;
    }
}
</style>
