import { EAssetType } from "@/entities/Asset";
export enum EEditorToggleNavigationName {
    DIAGRAM = "Диаграмма",
    TABLE = "Таблица",
    REGLAMENT = "Регламент",
}

export interface IActivityItem {
    name: string;
    parent: string;
    role: IAssigneeActivity;
    docs: IAssetItem[];
    systems: IAssetItem[];
}

export interface IAssetItem {
    id: number | null;
    name: string;
    type: EAssetType;
    icon: string;
}

export interface IAssigneeActivity {
    id: number | null;
    name: string;
    icon: string;
    color: string | undefined;
}
