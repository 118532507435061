<template>
    <Button
        :icon="PrimeIcons.PALETTE"
        :disabled="disabled"
        text
        class="navbar-button"
        severity="contrast"
        v-tooltip.bottom="'Изменить цвет выделенного'"
        @click="toggleColorPicker"
    />
    <ChangeColorOverlay />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useChangeElementColor, ChangeColorOverlay } from "../";

interface IProps {
    disabled?: boolean;
}

withDefaults(defineProps<IProps>(), {
    disabled: false,
});

const { toggleColorPicker } = useChangeElementColor();
</script>

<style scoped></style>
