<template>
    <Button
        :icon="buttonParams.icon"
        class="navbar-button"
        severity="contrast"
        :text="!menuSettings.isCheckErrors"
        v-tooltip.bottom="buttonParams.tooltipText"
        @click="actionMenuStore.toggleMenuSettings('isCheckErrors')"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { ShareInterfaceTypes } from "@/shared/types";

import { useDiagramActionMenuStore } from "@/entities/Process";

const actionMenuStore = useDiagramActionMenuStore();
const { menuSettings } = storeToRefs(actionMenuStore);

const buttonParams = computed<ShareInterfaceTypes.IButtonMenubarProps>(() => {
    const isCheckErrors = menuSettings.value.isCheckErrors;
    return {
        icon: isCheckErrors ? PrimeIcons.TIMES_CIRCLE : PrimeIcons.CHECK_CIRCLE,
        tooltipText: isCheckErrors ? "Убрать отображение ошибок" : "Проверять ошибки",
    };
});
</script>

<style scoped></style>
