import EditorActions from "diagram-js/lib/features/editor-actions/EditorActions";
import Keyboard, { Listener } from "diagram-js/lib/features/keyboard/Keyboard";
import { isCmd } from "diagram-js/lib/features/keyboard/KeyboardUtil";
import KeyboardBindings from "diagram-js/lib/features/keyboard/KeyboardBindings";

class BpmnKeyboardBindings extends KeyboardBindings {
    selection: any;
    copyPaste: any;
    moddle: any;
    clipboard: any;

    constructor(eventBus: any, keyboard: any) {
        super(eventBus, keyboard);
    }

    registerBindings(keyboard: Keyboard, editorActions: EditorActions): void {
        function addListener(action: string, fn: Listener) {
            if (editorActions.isRegistered(action)) {
                keyboard.addListener(fn);
            }
        }

        // undo
        // (CTRL|CMD) + Z
        addListener("undo", function (context) {
            const { keyEvent: event } = context;

            if (isUndo(event, keyboard)) {
                editorActions.trigger("undo", null);

                return true;
            }
        });

        // redo
        // CTRL + Y
        // CMD + SHIFT + Z
        addListener("redo", function (context) {
            const { keyEvent: event } = context;

            if (isRedo(event, keyboard)) {
                editorActions.trigger("redo", null);

                return true;
            }
        });

        // copy
        // CTRL/CMD + C
        addListener("copy", function (context) {
            const { keyEvent: event } = context;

            if (isKey(["KeyC"], event) && keyboard.isCmd(event)) {
                editorActions.trigger("copy", null);

                return true;
            }
        });

        // paste
        // CTRL/CMD + V
        addListener("paste", function (context) {
            const { keyEvent: event } = context;

            if (isKey(["KeyV"], event) && keyboard.isCmd(event)) {
                editorActions.trigger("paste", null);

                return true;
            }
        });

        // select all elements
        // CTRL + A
        addListener("selectElements", function (context) {
            const { keyEvent: event } = context;

            if (isKey(["KeyA"], event) && keyboard.isCmd(event)) {
                editorActions.trigger("selectElements", null);

                return true;
            }
        });

        // activate space tool
        // S
        addListener("spaceTool", function (context) {
            const { keyEvent: event } = context;

            if (keyboard.hasModifier(event)) {
                return;
            }

            if (isKey(["KeyS"], event)) {
                editorActions.trigger("spaceTool", null);

                return true;
            }
        });

        // activate lasso tool
        // L
        addListener("lassoTool", function (context) {
            const { keyEvent: event } = context;

            if (keyboard.hasModifier(event)) {
                return;
            }

            if (isKey(["KeyL"], event)) {
                editorActions.trigger("lassoTool", null);

                return true;
            }
        });

        // activate hand tool
        // H
        addListener("handTool", function (context) {
            const { keyEvent: event } = context;

            if (keyboard.hasModifier(event)) {
                return;
            }

            if (isKey(["KeyH"], event)) {
                editorActions.trigger("handTool", null);

                return true;
            }
        });

        // activate direct editing
        // E
        addListener("directEditing", function (context) {
            const { keyEvent: event } = context;

            if (keyboard.hasModifier(event)) {
                return;
            }

            if (isKey(["F2", "KeyE"], event)) {
                editorActions.trigger("directEditing", null);

                return true;
            }
        });

        // delete selected element
        // DEL
        addListener("removeSelection", function (context) {
            const { keyEvent: event } = context;

            if (isKey(["Backspace", "Delete"], event)) {
                editorActions.trigger("removeSelection", null);

                return true;
            }
        });
    }
}

// HELPERS //////////////////
function isKey(keys: string[] = [], event: KeyboardEvent) {
    const { code } = event;

    return keys.includes(code);
}

function isUndo(event: KeyboardEvent, keyboard: any) {
    return isKey(["KeyZ"], event) && keyboard.isCmd(event) && !keyboard.isShift(event);
}

function isRedo(event: KeyboardEvent, keyboard: any) {
    return keyboard.isCmd(event) && (isKey(["KeyY"], event) || (isKey(["KeyZ"], event) && keyboard.isShift(event)));
}

BpmnKeyboardBindings.$inject = ["eventBus", "keyboard"];

export default BpmnKeyboardBindings;
